import React, {useEffect, useState} from 'react';
import * as styles from './css/style.module.scss';
import { spline } from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';

export const SpTitle = (props) => {
    const {titleText, iconColor, clipPathId} = props;


    // todo blob作る処理は共通化したい
    // const refPath = useRef(null);
    const [d, setD] = useState("")

    // let hueNoiseOffset = 0;
    let noiseStep = 0.005;

    const points = createPoints();
    const simplex = new SimplexNoise();
    const reqIdRef = React.useRef();

    const animate = () => {
        setD(spline(points, 1, true))

        // for every point...
        for (let i = 0; i < points.length; i++) {
            const point = points[i];

            // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
            const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
            const x = map(nX, -1, 1, point.originX - 2, point.originX + 2);
            const y = map(nY, -1, 1, point.originY - 2, point.originY + 2);

            // update the point's current coordinates
            point.x = x;
            point.y = y;

            // progress the point's x, y values through "time"
            point.noiseOffsetX += noiseStep;
            point.noiseOffsetY += noiseStep;
        }

        reqIdRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        animate();
        return () => cancelAnimationFrame(reqIdRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    function noise(x, y) {
        return simplex.noise2D(x, y);
    }

    function createPoints() {
        const points = [];
        // how many points do we need
        const numPoints = 6;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 26;

        for (let i = 1; i <= numPoints; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;

            const x = 17 + Math.cos(theta) * rad;
            const y = 17 + Math.sin(theta) * rad;

            // store the point's position
            points.push({
                x: x,
                y: y,
                // we need to keep a reference to the point's original point for when we modulate the values later
                originX: x,
                originY: y,
                // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000
            });
        }

        return points;
    }


    return <div className={styles.main}>
        <div className={styles.icon} style={{
            clipPath:` url(#${clipPathId})`,
            background: `radial-gradient(129.35% 129.09% at 5.18% 10.86%, ${iconColor[2]} 0%, ${iconColor[1]} 41%, ${iconColor[0]} 100%)`,
        }}><svg width="100%" height="100%" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg">
                <clipPath id={clipPathId}>
                    <path transform="scale(0.36, 0.36) translate(12 12)"
                          d={d}/>
                </clipPath>
            </svg>
        </div>
        <span>{titleText}</span>
    </div>
}
