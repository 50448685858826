import React from 'react';
import * as styles from './css/style.module.scss';

export const Header = (props) => {
    const {spMenuDisplay, spMenuDisplayFunc, noMenu} = props
    const color = '#002649';
    return (
        <div className={styles.wrap}>
            <svg width="187" height="24" viewBox="0 0 187 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_585_6206)">
                    <path d="M65.7845 24.0001C58.7011 24.0001 53.9414 19.178 53.9414 12.0001C53.9414 4.82224 58.5708 0 65.4624 0C71.9776 0 76.3717 4.81144 76.3934 11.9751C76.3934 12.4242 76.3717 12.8804 76.3391 13.3224C76.3318 13.4119 76.2905 13.4953 76.2234 13.5561C76.1563 13.6169 76.0684 13.6505 75.9772 13.6502H59.0884C59.0565 13.6507 59.0251 13.6579 58.9964 13.6715C58.9677 13.685 58.9423 13.7044 58.9219 13.7285C58.9019 13.752 58.8873 13.7794 58.8792 13.8089C58.871 13.8385 58.8696 13.8694 58.8748 13.8996C59.5046 17.7131 62.2084 19.9906 66.1139 19.9906C68.4432 19.9502 70.7031 19.2034 72.5857 17.8522C72.6245 17.8241 72.6686 17.804 72.7154 17.7929C72.7622 17.7818 72.8108 17.7798 72.8583 17.7875C72.9059 17.7951 72.9513 17.8121 72.9922 17.8372C73.033 17.8624 73.0683 17.8954 73.096 17.9342L75.0035 20.6249C75.057 20.6991 75.0798 20.7906 75.067 20.8807C75.0542 20.9709 75.0069 21.0527 74.9347 21.1096C72.4083 23.0805 69.4873 24.0001 65.7845 24.0001ZM65.3647 4.08085C61.8537 4.08085 59.4974 6.21919 58.9002 9.97921C58.8949 10.0094 58.8964 10.0403 58.9045 10.0699C58.9126 10.0994 58.9272 10.1269 58.9472 10.1503C58.9676 10.1741 58.9931 10.1932 59.0219 10.2061C59.0507 10.2191 59.0821 10.2256 59.1137 10.2251H71.3441C71.3758 10.2256 71.4072 10.2191 71.436 10.2061C71.4648 10.1932 71.4902 10.1741 71.5106 10.1503C71.5303 10.1266 71.545 10.0993 71.5537 10.0699C71.5624 10.0405 71.565 10.0096 71.5613 9.97921C70.9713 6.22988 68.7127 4.08085 65.3647 4.08085Z" fill={color}/>
                    <path d="M174.519 24.0001C167.417 24.0001 162.061 18.8395 162.061 12.0001C162.061 5.16082 167.417 0 174.519 0C181.621 0 186.999 5.16082 186.999 12.0001C186.999 18.8395 181.635 24.0001 174.519 24.0001ZM174.519 4.34815C170.114 4.34815 166.918 7.55583 166.918 12.0001C166.918 16.4445 170.114 19.652 174.519 19.652C178.924 19.652 182.142 16.4445 182.142 12.0001C182.142 7.55583 178.938 4.34815 174.519 4.34815Z" fill={color}/>
                    <path d="M137.419 23.0378V5.13945C137.419 5.04492 137.381 4.95412 137.313 4.88729C137.245 4.82045 137.153 4.78305 137.057 4.78305H129.456C129.36 4.78305 129.268 4.74547 129.2 4.67863C129.132 4.61179 129.094 4.52117 129.094 4.42665V0.962357C129.094 0.867834 129.132 0.777209 129.2 0.710371C129.268 0.643533 129.36 0.605957 129.456 0.605957H150.127C150.223 0.605957 150.315 0.643533 150.383 0.710371C150.451 0.777209 150.489 0.867834 150.489 0.962357V4.42665C150.489 4.52117 150.451 4.61179 150.383 4.67863C150.315 4.74547 150.223 4.78305 150.127 4.78305H142.526C142.43 4.78305 142.338 4.82045 142.27 4.88729C142.202 4.95412 142.164 5.04492 142.164 5.13945V23.0378C142.164 23.1323 142.126 23.2229 142.058 23.2898C141.99 23.3566 141.898 23.3942 141.802 23.3942H137.791C137.743 23.3956 137.695 23.3874 137.65 23.3702C137.604 23.3529 137.563 23.3268 137.528 23.2936C137.494 23.2603 137.466 23.2206 137.447 23.1766C137.428 23.1327 137.419 23.0855 137.419 23.0378Z" fill={color}/>
                    <path d="M109.419 23.394C109.323 23.394 109.231 23.3565 109.163 23.2896C109.096 23.2228 109.058 23.1322 109.058 23.0376V10.6919C109.058 6.60041 106.936 4.34437 103.082 4.34437C99.2268 4.34437 97.1058 6.60041 97.1058 10.6919V23.0376C97.1058 23.1322 97.0676 23.2228 96.9997 23.2896C96.9318 23.3565 96.8398 23.394 96.7438 23.394H92.737C92.641 23.394 92.5489 23.3565 92.481 23.2896C92.4132 23.2228 92.375 23.1322 92.375 23.0376V10.2321C92.375 6.89977 93.5188 4.19113 95.6833 2.39131C97.5546 0.840964 100.182 -0.0107422 103.082 -0.0107422C108.022 -0.0107422 113.792 2.66937 113.792 10.2215V23.027C113.792 23.1216 113.754 23.2122 113.686 23.279C113.618 23.3459 113.526 23.3834 113.43 23.3834L109.419 23.394Z" fill={color}/>
                    <path d="M27.7035 -0.000159087C24.8079 -0.000159087 22.1728 0.851721 20.3015 2.40206C20.0463 2.60912 19.8046 2.83175 19.5776 3.06857C19.5437 3.10475 19.5026 3.13358 19.4568 3.15332C19.411 3.17307 19.3616 3.18325 19.3116 3.18325C19.2616 3.18325 19.2121 3.17307 19.1664 3.15332C19.1206 3.13358 19.0795 3.10475 19.0456 3.06857C16.9136 0.883839 13.8008 -0.00363956 10.9631 -0.00363956C10.0167 -0.00536051 9.0728 0.0914259 8.14708 0.285066C8.09146 0.297806 8.03975 0.323339 7.99606 0.359548C7.95237 0.395756 7.918 0.441584 7.89576 0.493372C7.87352 0.54516 7.86405 0.601391 7.8681 0.657476C7.87216 0.713562 7.88962 0.767848 7.91909 0.816012L9.97134 4.19833C10.0062 4.25525 10.0566 4.30141 10.1167 4.33164C10.1768 4.36187 10.2444 4.37496 10.3116 4.3694C10.5216 4.35158 10.7387 4.34086 10.9631 4.34086C14.8179 4.34086 16.939 6.5969 16.939 10.6884V23.0341C16.939 23.1287 16.9771 23.2193 17.045 23.2861C17.1129 23.353 17.2049 23.3905 17.3009 23.3905H21.3584C21.4544 23.3905 21.5465 23.353 21.6144 23.2861C21.6822 23.2193 21.7204 23.1287 21.7204 23.0341V10.6884C21.7204 6.5969 23.8415 4.34086 27.6963 4.34086C31.5511 4.34086 33.6721 6.5969 33.6721 10.6884V23.0341C33.6721 23.1287 33.7103 23.2193 33.7781 23.2861C33.846 23.353 33.9381 23.3905 34.0341 23.3905H38.0409C38.1369 23.3905 38.229 23.353 38.2969 23.2861C38.3647 23.2193 38.4029 23.1287 38.4029 23.0341V10.2286C38.4101 2.68004 32.6442 -0.000159087 27.7035 -0.000159087Z" fill={color}/>
                    <path d="M5.44379 5.0146C5.44379 3.53441 4.22516 2.33447 2.7219 2.33447C1.21863 2.33447 0 3.53441 0 5.0146C0 6.4948 1.21863 7.69473 2.7219 7.69473C4.22516 7.69473 5.44379 6.4948 5.44379 5.0146Z" fill={color}/>
                    <path d="M4.63217 10.9844H0.62172C0.421818 10.9844 0.259766 11.1439 0.259766 11.3408V23.0343C0.259766 23.2311 0.421818 23.3907 0.62172 23.3907H4.63217C4.83207 23.3907 4.99413 23.2311 4.99413 23.0343V11.3408C4.99413 11.1439 4.83207 10.9844 4.63217 10.9844Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_585_6206">
                        <rect width="187" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            {noMenu ? '' : <button onClick={() => spMenuDisplayFunc(!spMenuDisplay)} className={`${styles.spMenuButton} ${spMenuDisplay ? styles.active : ''}`}>
                <div className={styles.spMenuButton__1}/>
                <div className={styles.spMenuButton__2}/>
                <div className={styles.spMenuButton__3}/>
            </button>}
        </div>
    )
}
