import React from 'react';
import {Helmet} from "react-helmet";

export const Head = (props) => {
    const {data, pageData, noindex} = props;

    return <Helmet>
        <html lang="ja"/>
        <title>{data.site.siteMetadata.title}</title>
        <meta
            name="description"
            content={data.site.siteMetadata.description}
        />
        <meta property="og:url" content={`data.site.siteMetadata.siteUrl${pageData.page || ''}`}/>
        <meta property="og:type" content={pageData.type || 'website'}/>
        <meta property="og:title" content={pageData.title || data.site.siteMetadata.title}/>
        <meta property="og:description" content={pageData.description || data.site.siteMetadata.description}/>
        <meta property="og:site_name" content='mento Inc.'/>
        <meta property="og:image" content={process.env.PRODUCTION ? 'https://mento.co.jp/ogp.png' : 'https://mento-corp-stg.web.app/ogp.png'}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@mento_jp"/>
        {noindex ? <meta name="robots" content="noindex"/> : ''}
        {/*<meta property="fb:app_id" content="App-ID(15文字の半角数字)"/>*/}
            <script>
                {`(
                    function () {
                        if (window.outerWidth < 375) {
                            document.querySelector("meta[name='viewport']").setAttribute("content", "width=375,user-scalable=no,shrink-to-fit=yes");
                        }
                    }
                )();`}
            </script>
    </Helmet>
}
